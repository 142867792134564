<template>
  <b-modal
    id="modal-upload-signature"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    hide-footer
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
    @show="resetModal"
    @hide="resetModal"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">上傳簽署圖片</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col cols="12" v-if="signature_url">
              <b-form-group label="原簽署圖片" label-cols-md="3">
              <img class="img-fluid"
                    :src="signature_url"
                  />
                </b-form-group>
              <hr />
            </b-col>

            <b-col md="12"  >
              <b-form-group label="上傳圖片" label-cols-md="3">
                <el-upload
                  class="image-uploader"
                  list-type="picture-card"
                  ref="upload"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="image_file"
                  :file-list="fileList"
                  multiple
                  :limit="1"
                  :data="signatureData"
                  :on-change="onChange"
                  :on-remove="toggleUpload"
                  :auto-upload="false"
                  :class="{ hideUpload: !showUpload }"
                >
               
                <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from "@/store";
import axiosIns from "@/libs/axios";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormFile,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      loading: false,
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-upload-signature");
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    onSubmit() {
      // if(!this.signatureData.file){
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: "top-right",
      //     props: {
      //         title: "Error",
      //          text: `請上傳簽署圖片!`,
      //         icon: "AlertTriangleIcon",
      //         variant: "danger",
      //       },
      //   });
      //   return
      // }
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("signature", this.signatureData.file);
          this.loading = true;
          store
            .dispatch("app/uploadSignature", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              store.dispatch('app/fetchCoinsBalance')
              
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  computed: {
    ...mapGetters("app", [
      "signature_url"
    ]),
  },
  setup(props) {
    const loading = ref(false);
    const fileList = ref([]);
    const signatureData = ref({});
    const showUpload = ref(true);

    const onChange = (file, fileLists) => {
      showUpload.value = !showUpload.value;
      signatureData.value.file = file.raw;
    };
      const resetModal = () =>{
        showUpload.value = true
      }
    

    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/signature");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    return {
      loading,
      fileList,
      onChange,
      signatureData,
      resetModal,
      // For Image
      uploadUrl,
      headerObj,
      showUpload,

    };
  },
};
</script>


<style lang="scss">

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    border-radius:0%;
    
  }
  .hideUpload > div {
    display: none;
  }
</style>

<style lang="scss" scoped>
</style>