<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar
        :page-data2="pageData"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
      
    <div
      slot="vertical-menu-header"
      class="d-flex align-items-center justify-content-center h-100"
    >

    <modal-upload-signature ></modal-upload-signature>
      <div class="nav_div justify-content-center">
        <div class="nav_logo">
          <b-navbar-nav class="nav">
            <b-nav-item-dropdown
              rightx
              toggle-class="dropdown-user-link"
              class="dropdown-user"
            >
              <template #button-content>
                <div class="text-center nav-user-info">
                  <div class="nav-user-info_logo-wrapper">
                    <b-avatar
                      variant="light-primary"
                      badge
                      :src="require('@/assets/images/avatars/19.png')"
                      class="badge-minimal"
                      badge-variant="success"
                    />
                  </div>
                  <div class="nav-user-info_name">
                    <p class="mb-0">
                      {{ user_contact_name }}
                    </p>
                  </div>
                  <!-- <div class="nav-user-info_balance">
                    <span class="">餘額：{{ user_balance }}</span>
                  </div> -->
                  <div
                    class="bookmark-wrapper colorWhite align-items-center mt-25"
                  >
                    {{
                      new Date().getDate() +
                      " " +
                      monthNames[new Date().getMonth()] +
                      " " +
                      new Date().getFullYear() +
                      " " +
                      `( ${dayNames[new Date().getDay()]} )`
                    }}
                  </div>
                </div>
              </template>
              <b-dropdown-item v-if="false"
                link-class="d-flex align-items-center"
                to="/payment/paypal/exchange"
              >
                <feather-icon size="16" icon="DollarSignIcon" class="mr-50" />
                <span>信用卡充值</span>
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="handleAddSubAccount"
              >
                <feather-icon size="16" icon="UserPlusIcon" class="mr-50" />
                <span>新增子帳戶</span>
              </b-dropdown-item>

              <b-dropdown-divider />

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="handleEditAccount"
                >
                  <feather-icon size="16" icon="UsersIcon" class="mr-50" />
                  <span>公司資料</span>
                </b-dropdown-item>

                <b-dropdown-divider />


              <template v-if="!user_parent">
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="handleEditMoneylenderNumber"
                >
                  <feather-icon size="16" icon="UsersIcon" class="mr-50" />
                  <span>修改放債人號碼</span>
                </b-dropdown-item>

                <b-dropdown-divider />
                
              
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="handleUploadSignature"
                >
                  <feather-icon size="16" icon="EditIcon" class="mr-50" />
                  <span>上傳簽署</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                 
                <b-dropdown-item v-if="false"
                  link-class="d-flex align-items-center"
                  @click="handleDeleteSignature"
                >
                  <feather-icon size="16" icon="Trash2Icon" class="mr-50" />
                  <span>取消簽署</span>
                </b-dropdown-item>

                <b-dropdown-divider />
                
              </template>

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="handleEditPassword"
              >
                <feather-icon size="16" icon="EditIcon" class="mr-50" />
                <span>改變密碼</span>
              </b-dropdown-item>

              <b-dropdown-divider />

              <!-- <div
                class="d-md-none"
                v-if="
                  $store.state.app.user_info &&
                  $store.state.app.user_info.info.enable_lucky_draw
                "
              >
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  to="/luckyWheel"
                >
                  <font-awesome-icon class="mr-50" icon="fa-solid fa-gifts" />
                  <span>大抽獎</span>
                </b-dropdown-item>

                <b-dropdown-divider />
              </div> -->

              <div
                class="d-md-none"
                v-for="page of pageList"
                :key="'mobile' + page.slug + page.id"
              >
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="triggerModal(page)"
                >
                  <!-- <font-awesome-icon :icon="`fas ${page.icon}`" class="mr-50" /> -->
                  <span>{{ page.title }}</span>
                </b-dropdown-item>

                <b-dropdown-divider />
              </div>

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="handleLogout"
              >
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>登出</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-link to="/records-list" class="link-green d-flex justify-content-center">交易記錄</b-link>
        </div>
      </div>
    </div>

    <div slot="footer">
      <p class="mb-0">
        &copy; {{ companyName }} {{ new Date().getFullYear() }}
      </p>
      <edit-password />
    </div>
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from "../components/Navbar.vue";
import { $themeConfig } from "@themeConfig";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import EditPassword from "../components/EditPassword.vue";
import ModalPage from "../modal/ModalPage";
import ModalUploadSignature from "../modal/ModalUploadSignature";

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    ModalUploadSignature,

    FeatherIcon,
    ModalPage,
    EditPassword,
  },
  data() {
    return {
      pageData: {},
      pageList: [],
      trigger:false,
      // showCustomizer: $themeConfig.layout.customizer,
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
      companyName: $themeConfig.app.companyName,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dayNames: ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"],
    };
  },
  methods: {
    handleUploadSignature(){
      this.$bvModal.show('modal-upload-signature')
    },
    handleDeleteSignature(){
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這簽署。",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app/deleteSignature")
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$store.dispatch('app/fetchCoinsBalance')
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleEditPassword() {
      this.$root.$emit("bv::show::modal", "modal-edit-password", "#btnShow");
    },
    handleAddSubAccount() {
      this.$bvModal.show("modal-add-sub-account");
    },
    handleEditAccount() {
      this.$router.push({ name: "suppliers-edit" });
    },
    handleEditMoneylenderNumber(){
      this.$bvModal.show("modal-edit-moneylender-number");
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/logout");
      localStorage.clear();

      // localStorage.setItem("isWarningShow", 1);
      //Reqired to login page
      //Show the success message
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    },
    getTopNavList() {
      axiosIns.get("/page/list").then((response) => {
        this.pageList = response.data.pages;
      });
    },
    triggerModal(item) {
      this.pageData = { ...item };
      this.$bvModal.show("modal-page");
    },
  },
  computed: {
    ...mapGetters("app", [
      "user_contact_name",
      "user_company_name_cn",
      "user_balance",
      "user_parent",
    ]),
  },
  created() {
    this.getTopNavList();
  },
};
</script>

<style>
#menu_title {
  font-size: 0.9rem !important;
}
.nav_div {
  display: flex;
}
.nav_brand {
  margin-left: 10px;
  margin-top: 3px;
}
</style>
